<template>
  <div class="container">
    <SfLoader v-if="loading" :loading="loading" />
    <ProductSlider
      v-else-if="products && products.length > 0"
      :products="products"
      :title="$t(newProductsBlock.title || '')"
      :icon="newProductsBlock.icon || ''"
      slider-id="home-news-display"
    />
  </div>
</template>

<script>
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';
import { useFacet } from '@gemini-vsf/composables';
import { useUiHelpers } from '~/composables';
import ProductSlider from '~/components/ProductSlider.vue';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HomeNewsDisplay',
  components: {
    ProductSlider,
    SfLoader,
  },
  props: {
    newProductsBlock: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const uiHelpers = useUiHelpers();
    const searchSpecsStr = `facetId::homeNewsDisplay`;
    const { result, search: loadProducts, loading } = useFacet(searchSpecsStr);
    const products = ref([]);

    useFetch(async () => {
      await loadProducts({
        ...uiHelpers.getFacetsFromURL(),
        categoryId: `grn:product-list:product-list::${props.newProductsBlock.collection}`,
        itemsPerPage: 12,
        customQuery: {
          products: 'productListCustom',
        },
      });
      products.value =
        result?.value?.data?.items?.length > 0 && result.value.data.items;
    });

    return {
      loading,
      products,
    };
  },
});
</script>

<style lang="scss">
.home-news-display {
  width: 100%;
  position: relative;
  padding: 5.938rem 0;
  @include to-tablet-max {
    padding: 3rem 0;
  }
  h2 {
    @include font-italic;
    margin-bottom: 2.5rem;
    font-weight: normal;
    align-items: var(--align-items);
    @include to-tablet-max {
      font-size: var(--font-size-24);
      line-height: var(--line-height-24);
      margin-bottom: 1.813rem;
    }
    img {
      margin-right: 1.5rem;
      @include to-tablet-max {
        max-width: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
}
</style>
